<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Создать тест
            </VToolbarTitle>
          </VToolbar>

          <VCardText>
            <VRow>
              <VCol cols="6">
                <VForm>
                  <VTextField
                    v-model="name"
                    label="Название"
                  />
                  <VAutocomplete
                    v-model="skillId"
                    :items="skills.items"
                    :loading="skills.loading"
                    item-text="title"
                    item-value="id"
                    label="Навык"
                    :search-input.sync="searchSkills"
                  >
                    <template #selection="{ item }">
                      <VListItemContent>
                        <VListItemTitle v-text="item.name" />
                        <VListItemSubtitle
                          class="tt-text-body-2 tt-light-mono-46--text"
                          v-text="item.id"
                        />
                      </VListItemContent>
                    </template>

                    <template #item="{ item }">
                      <VListItemContent>
                        <VListItemTitle v-text="item.name" />
                        <VListItemSubtitle
                          class="tt-text-body-2 tt-light-mono-46--text"
                          v-text="item.id"
                        />
                      </VListItemContent>
                    </template>
                  </VAutocomplete>
                  <VSelect
                    v-model="type"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    label="Тип"
                  />
                </VForm>
              </VCol>
            </VRow>
          </VCardText>

          <VCardActions>
            <VBtn
              color="primary"
              @click="create"
            >
              Сохранить
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'TestCreate',
  inject: ['Names'],
  data() {
    return {
      name: '',
      skillId: null,
      skills: {
        items: [],
        loading: false,
      },
      type: '',
      types: [
        {
          value: 'Test::Eight',
          name: 'Многоуровневый',
        },
        {
          value: 'Test::Binary',
          name: 'Базовый',
        },
      ],
      searchSkills: null,
    };
  },
  watch: {
    searchSkills: {
      async handler(value) {
        await this.fetchSkills(value);
      },
    },
  },
  methods: {
    async create() {
      try {
        const { name, skillId, type } = this;

        const payload = {
          name,
          skillId,
          type,
          extra: 'Экстра',
          thankyou: 'Спасибо',
          description: 'Описание',
        };

        const response = await this.$di.api.Talent.testCreate(payload);
        this.$router.push({ name: this.Names.R_TALENT_TEST_EDIT, params: { testId: response.id } });

        this.$di.notify.snackSuccess('Тест создан');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async fetchSkills(query = '') {
      this.skills.loading = true;
      try {
        const { skills } = await this.$di.api.CompetencyMatrix.SkillsSearch({ query });
        this.skills.items = skills.map((skill) => ({
          ...skill,
          title: `${skill.name} ${skill.id}`,
        }));
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.skills.loading = false;
      }
    },
  },
};
</script>
